//@author: devin

import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output } from "@angular/core";
import { S25Util } from "../../util/s25-util";
import { Api } from "../../api/api";
import { S25LoadingApi } from "../s25-loading/loading.api";

export class EditableApi extends Api {
    public static refresh(editableElem: HTMLElement, uuid?: string) {
        return Api.callApiFn(editableElem, "", "refresh", null, null, (comp: any) => {
            return !uuid || (comp && comp.uuid === uuid);
        });
    }
}

@Component({ template: "" })
export class S25EditableAbstract implements OnInit {
    static DEFAULT_MIN: number = 0;
    static DEFAULT_MAX: number = 9999999;

    @Input() min: number = S25EditableAbstract.DEFAULT_MIN;
    @Input() max: number = S25EditableAbstract.DEFAULT_MAX;
    @Input() disabled: boolean = false;
    @Input() allowEmpty: boolean = true;
    @Input() hasCommit: boolean;
    @Input() hasCancelButton: boolean;
    @Input() hasCommitButton: boolean;
    @Input() alwaysEditing: boolean = false;
    @Input() cancelButtonText: string = "Cancel";
    @Input() commitButtonText: string = "Save";
    @Input() onCommit?: (inputVal: any) => any; //runs once user has committed
    @Input() onUpdate: (inputVal: any) => any; //if NOT hasCommit, this runs on each model val update
    @Input() onBlur: (inputVal: any) => any; //if NOT hasCommit, this runs on blur after val updated
    @Input() readOnly: boolean = false;
    @Input() val: any;
    @Input() customValidation: (inputVal: any) => boolean | string[] | Promise<boolean | string[]>;
    @Input() valOnInit: boolean = false; // run validation on init if true; useful if value is being populated like in copy object
    @Input() fieldID: string; // id value for input/textarea or other fields to connect to label for accessibility
    @Input() placeholder?: string; // if no val, option to show placeholder text
    @Input() rows?: number = 2; // rows option for textarea, make it flexible  to  adjust the size
    @Input() cols?: number = 20; // cols option for textarea, make it flexible  to  adjust size

    @Output() valChange = new EventEmitter<any>();
    @Output() blurred = new EventEmitter<string>();
    @Output() disablingError = new EventEmitter<boolean>(); // use to disable buttons in parent component if error (example in s25.copy.object.component.ts)

    candidateVal: any;
    errorMessages: string[];
    editing: boolean = false;
    committing: boolean = false;
    uuid: string = "editable-" + Date.now() + "-" + Math.floor(Math.random() * 10000);

    elem: ElementRef;
    cdRef: ChangeDetectorRef;
    zoneRef: NgZone;

    getType = (): string => {
        throw new Error("Undefined type");
    };

    static mergeValidations = (resp1: boolean | string[], resp2: boolean | string[]) => {
        if (resp1 === true && resp2 === true) {
            return true;
        }

        let errors: string[] = [];
        if (resp1 !== true) {
            errors = errors.concat(resp1 as string[]);
        }
        if (resp2 !== true) {
            errors = errors.concat(resp2 as string[]);
        }
        return errors.filter((err) => !!err);
    };

    refresh = () => {
        this.cdRef.detectChanges();
    };

    validate = async (inputVal: any) => {
        let ret: boolean | string[] = true;
        let isBlank = S25Util.isUndefined(inputVal) || inputVal === "";
        let isInvalidInt =
            this.getType() === "integer" &&
            ((!S25Util.isInt(inputVal) && (!isBlank || !this.allowEmpty)) || !/^-?\d+$/.test(String(inputVal)));
        let isInvalidFloat =
            ["percentage", "float"].indexOf(this.getType()) > -1 &&
            !S25Util.isFloat(inputVal) &&
            (!isBlank || !this.allowEmpty);
        let inputValSize =
            ["integer", "percentage", "float"].indexOf(this.getType()) > -1
                ? parseFloat(inputVal)
                : ["text", "textarea", "richtext"].indexOf(this.getType()) > -1
                  ? S25Util.toStr(inputVal).length
                  : null;

        if (isBlank && !this.allowEmpty) {
            ret = ["Please enter a non-blank value."];
        } else if (isInvalidInt || isInvalidFloat) {
            ret = ["Please enter a valid " + this.getType() + "."];
        } else if (inputValSize < this.min) {
            ret = ["Please enter a " + this.getType() + " size greater than or equal to " + this.min + "."];
        } else if (inputValSize > this.max) {
            ret = ["Please enter a " + this.getType() + " size less than or equal to " + this.max + "."];
        }

        if (this.customValidation) {
            const submitButton = this.elem.nativeElement.querySelector(".aw-button.aw-button--primary");
            submitButton && S25LoadingApi.init(submitButton);
            const error = await this.customValidation(inputVal);
            submitButton && S25LoadingApi.destroy(submitButton);
            return S25EditableAbstract.mergeValidations(ret, error);
        } else {
            return ret;
        }
    };

    //update candidate and run validation (optionally)
    _update = async (candidateVal: any, skipValidate?: boolean) => {
        this.candidateVal = candidateVal;
        this.errorMessages = null;

        if (!skipValidate) {
            let validation = await this.validate(this.candidateVal);
            if (validation === true) {
                this.val = this.candidateVal;
                this.getType() === "duration"
                    ? this.valChange.emit(S25Util.daysHoursMinutesToDuration(this.val))
                    : this.valChange.emit(this.val);
                this.disablingError.emit(false);
            } else {
                this.errorMessages = validation as string[];
                if (this.errorMessages) {
                    this.disablingError.emit(true);
                }
            }

            this.cdRef.detectChanges();

            return validation;
        }
    };

    //update model: if hasCommit, just update candidate and save validation for later; else run validation too and onUpdate func
    update = async (candidateVal: any, fromBlur?: boolean, duration?: any) => {
        if (duration === "days") {
            this.candidateVal.days = candidateVal;
        } else if (duration === "hours") {
            this.candidateVal.hours = candidateVal;
        } else if (duration === "minutes") {
            this.candidateVal.minutes = candidateVal;
        }
        if (this.getType() === "duration") {
            this.val = S25Util.daysHoursMinutesToDuration(this.candidateVal);
            candidateVal = this.candidateVal;
        }

        if (this.hasCommit) {
            this._update(candidateVal, true);
        } else {
            let validation = await this._update(candidateVal);
            if (validation === true) {
                this.onUpdate && this.onUpdate(this.val);
                fromBlur && this.onBlur && this.onBlur(this.val);
                if (fromBlur) this.blurred.emit(this.val);
            }
        }
    };

    commit = async () => {
        this.committing = true;
        let validation = await this._update(this.candidateVal);
        if (validation === true) {
            this.onCommit && this.onCommit(this.val);
            this.close();
        }
        setTimeout(() => {
            this.committing = false;
            this.cdRef.detectChanges();
        }, 10);
    };

    //either commit or update and close
    submit = (candidateVal?: any, fromBlur?: boolean) => {
        if (this.hasCommit) {
            this.commit();
        } else {
            this.update(candidateVal || this.candidateVal, fromBlur);
            this.close();
        }
    };

    close = () => {
        this.editing = false || this.alwaysEditing;
        this.cdRef.detectChanges();
    };

    cancel = () => {
        this.candidateVal = this.val;
        this.close();
    };

    onInputBlur = () => {
        setTimeout(() => {
            if (!this.committing) {
                if (this.hasCommit && !this.elem.nativeElement.contains(document.activeElement)) {
                    if (this.hasCommit && this.candidateVal !== this.val) {
                        this.submit(this.candidateVal, true);
                    } else {
                        this.cancel();
                    }
                } else if (!this.hasCommit) {
                    this.submit(this.candidateVal, true);
                }
            }
        }, 1);
    };

    editMode = () => {
        this.editing = !this.editing;
        this.cdRef.detectChanges();
        this.zoneRef.run(() => {
            let elem = this.elem.nativeElement.querySelector(".firstFocusEditable");
            elem && elem.focus();
        });
    };

    constructor(elementRef: ElementRef, cd: ChangeDetectorRef, zone: NgZone) {
        this.elem = elementRef;
        this.cdRef = cd;
        this.zoneRef = zone;
        this.elem.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    ngOnInit() {
        this.min = S25Util.coalesce(this.min, S25EditableAbstract.DEFAULT_MIN);
        this.max = S25Util.coalesce(this.max, S25EditableAbstract.DEFAULT_MAX);

        this.getType() === "duration"
            ? (this.candidateVal = S25Util.ISODurationToObj(this.val))
            : (this.candidateVal = this.val);

        if (this.alwaysEditing) {
            this.hasCommit = false;
            this.editing = true;
        }

        if (this.val && this.candidateVal && this.valOnInit) {
            this.update(this.candidateVal);
        }

        if (this.hasCommit) {
            if (this.hasCommitButton !== false) {
                this.hasCommitButton = true;
            }

            if (this.hasCancelButton !== false) {
                this.hasCancelButton = true;
            }
        } else {
            this.hasCommitButton = false;
            this.hasCancelButton = false;
        }

        this.cdRef.detectChanges();
    }
}
